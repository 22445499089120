<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          label-width="70px"
          :inline="true"
          :model="form"
          @submit.native.prevent
        >
          <el-form-item label="供应商">
            <el-select
              v-model="form.supplierIds"
              multiple
              filterable
              clearable
              collapse-tags
              @change="handleBlur"
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.baseSupplierGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="提货地址">
            <el-input
              clearable
              v-model="form.pickName"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入提货地址"
            />
          </el-form-item>

          <el-form-item label="送货地址">
            <el-input
              clearable
              v-model="form.dispatchName"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入送货地址"
            />
          </el-form-item>

          <el-form-item label="创建时间">
            <el-date-picker
              v-model="beginTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptions"
              unlink-panels
              @change="pickerClick"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item v-show="!fold" label="产品专员">
            <el-select
              v-model="form.productCommissionerId"
              @change="handleBlur"
              filterable
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="编号" v-show="!fold">
            <el-input
              clearable
              v-model="form.code"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入编号"
            />
          </el-form-item>
          <!-- <el-form-item label="是否含税" v-show="!fold">
            <el-select
              v-model="form.includingTaxes"
              @change="handleBlur"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.taxList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="失效时间" v-show="!fold">
            <el-date-picker
              v-model="beginTimeOne"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
              :picker-options="pickerOptionsOne"
              unlink-panels
              @change="pickerClickOne"
              style="width: 350px"
            ></el-date-picker>
          </el-form-item>
          <el-form-item label="状态" v-show="!fold">
            <el-select
              v-model="form.status"
              filterable
              clearable
              @change="handleBlur"
              placeholder="请选择"
              style="width: 100px"
            >
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="20">
        <el-button
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="release"
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          v-permissions="{ ability: ['RELEASE'] }"
        >
          发布
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          @click="loseEfficacy"
          v-permissions="{ ability: ['EXPIRE'] }"
        >
          <vab-icon icon="forbid-2-line" />
          失效
        </el-button>
        <el-button
          size="mini"
          type="primary"
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          @click="statusFallback"
          v-permissions="{ ability: ['CANCEL'] }"
        >
          <vab-icon icon="logout-circle-line" />
          状态回退
        </el-button>
        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="handExport"
          :loading="anLoading"
        >
          导出
        </el-button>
        <el-button
          icon="el-icon-delete"
          type="danger"
          size="mini"
          @click="handDelete"
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          v-permissions="{ ability: ['DELETE'] }"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="4">
        <el-checkbox v-model="isQueryAll" label="操作查询到的全部数据" />
      </vab-query-form-right-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 330px)">
      <vxe-table
        border
        show-overflow
        height="100%"
        :row-config="{ isHover: true, isCurrent: true, height: 40 }"
        :data="list"
        :scroll-y="{ enabled: true }"
        v-loading="listLoading"
        :checkbox-config="{ trigger: 'row', range: true }"
        @checkbox-change="selectAllEvent"
        @checkbox-all="selectAllEvent"
        ref="tableSort"
        :row-class-name="rowStyle"
        :resizable="true"
      >
        <!-- <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
      element-loading-text="拼命加载中"
      @selection-change="setSelectRows"
      header-cell-class-name="table-header"
      class="table eleChange"
      :row-style="{ height: '30px' }"
      :cell-style="{ padding: '2px' }"
      :row-class-name="rowStyle"
      @row-click="handleRowClick"
    > -->

        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          field="line"
          fixed="left"
          title="线路"
          width="120"
        />
        <vxe-column
          align="center"
          field="supplier"
          title="供应商"
          width="100"
        />
        <vxe-colgroup title="提货地址" align="center">
          <vxe-column
            field="pickCityName"
            align="center"
            title="起运市"
            width="80"
          ></vxe-column>
          <vxe-column
            field="pickAreasName"
            align="center"
            title="起运区"
            width="80"
          ></vxe-column>
          <vxe-column
            field="pickStreetName"
            align="center"
            title="起运街道"
            width="100"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup title="送货地址" align="center">
          <vxe-column
            field="dispatchCityName"
            align="center"
            title="目的市"
            width="80"
          ></vxe-column>
          <vxe-column
            field="dispatchAreasName"
            align="center"
            title="目的区"
            width="80"
          ></vxe-column>
          <vxe-column
            field="dispatchStreetName"
            align="center"
            title="目的街道"
            width="100"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-column
          title="泡货(CBM)"
          field="bulkyCargo"
          align="center"
          width="110"
        ></vxe-column>
        <vxe-column
          align="center"
          field="heavyCargoChanget"
          title="重货(T)"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="heavyCargo"
          title="重货(KG)"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="weightToVolumeRatio"
          title="重量转化体积比例"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="ageing"
          title="时效"
          show-overflow-tooltip
          width="90"
        />
        <vxe-column
          align="center"
          field="includingTaxes"
          title="是否含税"
          show-overflow-tooltip
          width="90"
        />
        <vxe-colgroup align="center" title="提货成本价(CBM)">
          <vxe-column
            title="5"
            align="center"
            field="pickCostStep7"
            width="80"
          ></vxe-column>
          <vxe-column
            title="10"
            align="center"
            field="pickCostStep8"
            width="80"
          ></vxe-column>
          <vxe-column
            title="15"
            align="center"
            field="pickCostStep1"
            width="80"
          ></vxe-column>
          <vxe-column
            title="20"
            align="center"
            field="pickCostStep2"
            width="80"
          ></vxe-column>
          <vxe-column
            title="25"
            align="center"
            field="pickCostStep3"
            width="80"
          ></vxe-column>
          <vxe-column
            title="30"
            align="center"
            field="pickCostStep4"
            width="80"
          ></vxe-column>
          <vxe-column
            title="35"
            align="center"
            field="pickCostStep5"
            width="80"
          ></vxe-column>
          <vxe-column
            title="40"
            align="center"
            field="pickCostStep6"
            width="80"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-colgroup align="center" title="送货成本价(CBM)">
          <vxe-column
            title="5"
            align="center"
            field="dispatchCostStep7"
            width="80"
          ></vxe-column>
          <vxe-column
            title="10"
            align="center"
            field="dispatchCostStep8"
            width="80"
          ></vxe-column>
          <vxe-column
            title="15"
            align="center"
            field="dispatchCostStep1"
            width="80"
          ></vxe-column>
          <vxe-column
            title="20"
            align="center"
            field="dispatchCostStep2"
            width="80"
          ></vxe-column>
          <vxe-column
            title="25"
            align="center"
            field="dispatchCostStep3"
            width="80"
          ></vxe-column>
          <vxe-column
            title="30"
            align="center"
            field="dispatchCostStep4"
            width="80"
          ></vxe-column>
          <vxe-column
            title="35"
            align="center"
            field="dispatchCostStep5"
            width="80"
          ></vxe-column>
          <vxe-column
            title="40"
            align="center"
            field="dispatchCostStep6"
            width="80"
          ></vxe-column>
        </vxe-colgroup>
        <vxe-column
          align="center"
          field="profitMarkupRatio"
          title="利润加成比例"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="minimumProfit"
          title="最低利润"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyRatio1"
          title="重泡比比例1"
          show-overflow-tooltip
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyRatio2"
          title="重泡比比例2"
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyMarkupRatio"
          title="重泡货加成比例"
          width="110"
        />

        <vxe-column
          align="center"
          field="remark"
          title="备注"
          show-overflow-tooltip
          width="350"
        />
        <vxe-column
          align="center"
          field="effectiveDate"
          title="生效日"
          width="110"
        />
        <vxe-column
          align="center"
          field="expiringDate"
          title="失效日"
          width="110"
        />
        <vxe-column
          align="center"
          field="createBy"
          title="创建人"
          width="110"
        />
        <vxe-column
          align="center"
          field="createTime"
          title="创建时间"
          width="110"
        />
        <vxe-column
          align="center"
          field="updateBy"
          title="修改人"
          width="110"
        />
        <vxe-column
          align="center"
          field="updateTime"
          title="修改时间"
          width="110"
        />
        <vxe-column align="center" field="code" title="编号" width="110" />
        <vxe-column
          align="center"
          field="productCommissioner"
          title="产品专员"
          width="100"
          fixed="right"
        />
        <vxe-column
          align="center"
          field="status"
          title="状态"
          fixed="right"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '待发布'">
              <el-tag>{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '已发布'">
              <el-tag type="danger">{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '失效'">
              <el-tag type="info">{{ scope.row.status }}</el-tag>
            </span>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
      :page-sizes="[10, 50, 100, 500, 1000]"
    />
  </div>
</template>
<script>
  import {
    lessThanTruckServiceGetPageList,
    lessThanTruckServiceExpire,
    lessThanTruckServiceDelete,
    lessThanTruckServiceRelease,
    lessThanTruckServiceCancelExport,
    lessThanTruckServiceCancelRelease,
  } from '@/api/productCenter'
  export default {
    props: {
      optionList: Object,
    },
    data() {
      return {
        anLoading: false,
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2) - 50,
        list: [],
        form: {
          beginDate: '',
          endDate: '',
          supplierIds: [],
          code: '',
          // includingTaxes: '',
          pickName: '',
          dispatchName: '',
          status: '',
          productCommissionerId: '',
          pageNum: 1,
          pageSize: 100,
        },
        isQueryAll: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        optionListOne: [
          {
            id: 'inquiryNum',
            name: '询价量',
          },
          {
            id: 'dealNum',
            name: '已成交询价量',
          },
          {
            id: 'notDealNum',
            name: '未成交询价量',
          },
          {
            id: 'dealPercent',
            name: '询价成交率',
          },
          {
            id: 'quotationPriceSum',
            name: '报价总金额',
          },
          {
            id: 'dealPriceSum',
            name: '成交总金额',
          },
        ],
        optionListTwo: [
          {
            id: '0',
            name: '待发布',
          },
          {
            id: '1',
            name: '已发布',
          },
          {
            id: '2',
            name: '失效',
          },
        ],
        bulkOperations: {
          ids: [],
        },
        beginTime: [],
        beginTimeOne: [],
        pickerOptionsOne: {},
        pickerOptions: {},
      }
    },
    mounted() {
      // this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      pickerClickOne() {
        this.form.expiringBeginDate = this.beginTimeOne
          ? this.beginTimeOne[0]
          : ''
        this.form.expiringEndDate = this.beginTimeOne
          ? this.beginTimeOne[1]
          : ''
        this.queryList()
      },
      selectAllEvent({ records }) {
        this.bulkOperations.ids = records.map((item, index, array) => item.id)
      },
      // setSelectRows(val) {
      //   this.bulkOperations.ids = val.map((item, index, array) => item.id)
      // },
      rowStyle({ row }) {
        var arr = this.bulkOperations.ids
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleBlur() {
        this.form.pageNum = 1
        this.queryList()
      },
      async queryList() {
        this.bulkOperations.ids = []
        this.listLoading = true
        const res = await lessThanTruckServiceGetPageList(this.form)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      //发布
      async release() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认发布当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckServiceRelease(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定发布吗', '提示', async () => {
            const res = await lessThanTruckServiceRelease(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      //失效
      loseEfficacy() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认失效当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckServiceExpire(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定失效吗', '提示', async () => {
            const res = await lessThanTruckServiceExpire(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      //状态回退
      statusFallback() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认状态回退当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckServiceCancelRelease(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定状态回退吗', '提示', async () => {
            const res = await lessThanTruckServiceCancelRelease(
              this.bulkOperations
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      //导出
      async handExport() {
        this.anLoading = true
        this.$set(this.form, 'ids', this.bulkOperations.ids)
        // this.$baseConfirm('确定导出吗', '提示', async () => {
        const res = await lessThanTruckServiceCancelExport(this.form)
        const link = document.createElement('a')
        link.href = res.data.url
        link.click()
        this.anLoading = false
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
        // })
      },
      //删除
      handDelete() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认删除当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckServiceDelete(payload)
              
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        }else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定删除吗', '提示', async () => {
            const res = await lessThanTruckServiceDelete(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  ::v-deep {
    .rowStyle {
      background-color: #e8f7fd !important;
      // color: #1890ff;
    }
  }
</style>
