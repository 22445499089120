<template>
  <div class="comprehensive-table-container">
    <vab-query-form>
      <vab-query-form-top-panel>
        <el-form
          ref="form"
          :inline="true"
          label-width="70px"
          :model="form"
          @submit.native.prevent
        >
          <el-form-item label="供应商">
            <el-select
              v-model="form.supplierIds"
              clearable
              collapse-tags
              filterable
              multiple
              placeholder="请选择"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.baseSupplierGetValidList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
            <!-- <el-input
              clearable
              v-model="form.supplier"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
              @blur="handleBlur"
              placeholder="请输入提货地址"
            /> -->
          </el-form-item>

          <el-form-item label="提货地址">
            <el-input
              v-model="form.pickName"
              clearable
              placeholder="请输入提货地址"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>

          <el-form-item label="送货地址">
            <el-input
              v-model="form.dispatchName"
              clearable
              placeholder="请输入送货地址"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>

          <el-form-item label="创建时间">
            <el-date-picker
              v-model="beginTime"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              range-separator="至"
              start-placeholder="开始日期"
              style="width: 350px"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="pickerClick"
            />
          </el-form-item>
          <el-form-item v-show="!fold" label="产品专员">
            <el-select
              v-model="form.productCommissionerId"
              clearable
              filterable
              placeholder="请选择"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionList.productCommissionerList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-show="!fold" label="编号">
            <el-input
              v-model="form.code"
              clearable
              placeholder="请输入编号"
              @blur="handleBlur"
              @change="handleBlur"
              @keyup.enter.native="handleBlur"
            />
          </el-form-item>
          <!-- <el-form-item label="是否含税" v-show="!fold">
            <el-select
              v-model="form.includingTaxes"
              @change="handleBlur"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in optionList.taxList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item v-show="!fold" label="失效时间">
            <el-date-picker
              v-model="beginTimeOne"
              end-placeholder="结束日期"
              :picker-options="pickerOptionsOne"
              range-separator="至"
              start-placeholder="开始日期"
              style="width: 350px"
              type="daterange"
              unlink-panels
              value-format="yyyy-MM-dd"
              @change="pickerClickOne"
            />
          </el-form-item>
          <el-form-item v-show="!fold" label="状态">
            <el-select
              v-model="form.status"
              clearable
              filterable
              placeholder="请选择"
              style="width: 100px"
              @change="handleBlur"
            >
              <el-option
                v-for="item in optionListTwo"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="text" @click="handleFold">
              <span v-if="fold">展开</span>
              <span v-else>合并</span>
              <vab-icon
                class="vab-dropdown"
                :class="{ 'vab-dropdown-active': fold }"
                icon="arrow-up-s-line"
              />
            </el-button>
          </el-form-item>
        </el-form>
      </vab-query-form-top-panel>
      <vab-query-form-left-panel :span="20">
        <el-dialog
          :before-close="handleClose"
          title="导入"
          :visible.sync="dialogVisible"
          width="500px"
        >
          <span>
            <el-upload
              v-loading="loading"
              :action="importExectUrl()"
              class="upload-demo"
              drag
              :http-request="importExect"
              :show-file-list="false"
              with-credentials
            >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                将文件拖到此处，或
                <em>点击上传</em>
              </div>
            </el-upload>
          </span>
          <span slot="footer" class="dialog-footer">
            <!-- <el-button v-show="haveFail" @click="errorLink()">
              导出错误跳转查看
            </el-button> -->
            <el-button @click="dialogVisible = false">取 消</el-button>
            <!-- <el-button type="primary" @click="importExectUrl()">确 定</el-button> -->
          </span>
        </el-dialog>
        <el-button
          icon="el-icon-download"
          size="mini"
          type="primary"
          @click="baseyCode('', 'add')"
        >
          下载模板
        </el-button>
        <el-button
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="dialogVisible = true"
        >
          导入
        </el-button>
        <el-button
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          icon="el-icon-circle-check"
          size="mini"
          type="primary"
          @click="release"
        >
          提交
        </el-button>
        <el-button
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          size="mini"
          type="primary"
          @click="loseEfficacy"
        >
          <vab-icon icon="forbid-2-line" />
          失效
        </el-button>
        <el-button
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          size="mini"
          type="primary"
          @click="statusFallback"
        >
          <vab-icon icon="logout-circle-line" />
          状态回退
        </el-button>

        <el-button
          icon="el-icon-download"
          :loading="anLoading"
          size="mini"
          type="primary"
          @click="handExport"
        >
          导出
        </el-button>
        <el-button
          :disabled="!bulkOperations.ids.length > 0 && !isQueryAll"
          icon="el-icon-delete"
          size="mini"
          type="danger"
          @click="handDelete"
        >
          删除
        </el-button>
      </vab-query-form-left-panel>
      <vab-query-form-right-panel :span="4">
        <el-checkbox v-model="isQueryAll" label="操作查询到的全部数据" />
      </vab-query-form-right-panel>
    </vab-query-form>
    <div style="height: calc(100vh - 330px)">
      <vxe-table
        v-loading="listLoading"
        border
        :checkbox-config="{ trigger: 'row', range: true, highlight: true }"
        :data="list"
        ref="tableSort"
        height="100%"
        :row-class-name="rowStyle"
        :row-config="{ isHover: true, isCurrent: true, height: 40 }"
        :resizable="true"
        :scroll-y="{ enabled: true }"
        show-overflow
        @checkbox-all="selectAllEvent"
        @checkbox-change="selectAllEvent"
      >
        <!-- <el-table
      ref="tableSort"
      v-loading="listLoading"
      border
      :data="list"
      :height="height"
      element-loading-text="拼命加载中"
      @selection-change="setSelectRows"
      header-cell-class-name="table-header"
      class="table eleChange"
      :row-style="{ height: '30px' }"
      :cell-style="{ padding: '2px' }"
      :row-class-name="rowStyle"
      @row-click="handleRowClick"
    > -->

        <vxe-column align="center" fixed="left" type="checkbox" width="55" />
        <vxe-column
          align="center"
          field="line"
          fixed="left"
          title="线路"
          width="120"
        />
        <vxe-column
          align="center"
          field="supplier"
          title="供应商"
          width="100"
        />
        <vxe-colgroup align="center" title="提货地址">
          <vxe-column
            align="center"
            field="pickCityName"
            title="起运市"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickAreasName"
            title="起运区"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickStreetName"
            title="起运街道"
            width="100"
          />
        </vxe-colgroup>
        <vxe-colgroup align="center" title="送货地址">
          <vxe-column
            align="center"
            field="dispatchCityName"
            title="目的市"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchAreasName"
            title="目的区"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchStreetName"
            title="目的街道"
            width="100"
          />
        </vxe-colgroup>
        <vxe-column
          align="center"
          field="bulkyCargo"
          title="泡货(CBM)"
          width="110"
        />
        <vxe-column
          align="center"
          field="heavyCargoChanget"
          show-overflow-tooltip
          title="重货(T)"
          width="110"
        />
        <vxe-column
          align="center"
          field="heavyCargo"
          show-overflow-tooltip
          title="重货(KG)"
          width="110"
        />
        <vxe-column
          align="center"
          field="weightToVolumeRatio"
          show-overflow-tooltip
          title="重量转化体积比例"
          width="110"
        />
        <vxe-column
          align="center"
          field="ageing"
          show-overflow-tooltip
          title="时效"
          width="90"
        />
        <vxe-column
          align="center"
          field="includingTaxes"
          show-overflow-tooltip
          title="是否含税"
          width="90"
        />
        <vxe-colgroup align="center" title="提货成本价(CBM)">
          <vxe-column
            align="center"
            field="pickCostStep7"
            title="5"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep8"
            title="10"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep1"
            title="15"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep2"
            title="20"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep3"
            title="25"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep4"
            title="30"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep5"
            title="35"
            width="80"
          />
          <vxe-column
            align="center"
            field="pickCostStep6"
            title="40"
            width="80"
          />
        </vxe-colgroup>
        <vxe-colgroup align="center" title="送货成本价(CBM)">
          <vxe-column
            align="center"
            field="dispatchCostStep7"
            title="5"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep8"
            title="10"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep1"
            title="15"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep2"
            title="20"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep3"
            title="25"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep4"
            title="30"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep5"
            title="35"
            width="80"
          />
          <vxe-column
            align="center"
            field="dispatchCostStep6"
            title="40"
            width="80"
          />
        </vxe-colgroup>
        <vxe-column
          align="center"
          field="profitMarkupRatio"
          show-overflow-tooltip
          title="利润加成比例"
          width="110"
        />
        <vxe-column
          align="center"
          field="minimumProfit"
          show-overflow-tooltip
          title="最低利润"
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyRatio1"
          show-overflow-tooltip
          title="重泡比比例1"
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyRatio2"
          title="重泡比比例2"
          width="110"
        />
        <vxe-column
          align="center"
          field="bulkyHeavyMarkupRatio"
          title="重泡货加成比例"
          width="110"
        />

        <vxe-column
          align="center"
          field="remark"
          show-overflow-tooltip
          title="备注"
          width="350"
        />
        <vxe-column
          align="center"
          field="effectiveDate"
          title="生效日"
          width="110"
        />
        <vxe-column
          align="center"
          field="expiringDate"
          title="失效日"
          width="110"
        />
        <vxe-column
          align="center"
          field="createBy"
          title="创建人"
          width="110"
        />
        <vxe-column
          align="center"
          field="createTime"
          title="创建时间"
          width="110"
        />
        <vxe-column
          align="center"
          field="updateBy"
          title="修改人"
          width="110"
        />
        <vxe-column
          align="center"
          field="updateTime"
          title="修改时间"
          width="110"
        />
        <vxe-column align="center" field="code" title="编号" width="110" />
        <vxe-column
          align="center"
          field="productCommissioner"
          fixed="right"
          title="产品专员"
          width="100"
        />
        <vxe-column
          align="center"
          field="status"
          fixed="right"
          title="状态"
          width="80"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.status == '待提交'">
              <el-tag>{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '已提交'">
              <el-tag type="danger">{{ scope.row.status }}</el-tag>
            </span>
            <span v-if="scope.row.status == '失效'">
              <el-tag type="info">{{ scope.row.status }}</el-tag>
            </span>
          </template>
        </vxe-column>
        <template #empty>
          <el-image
            class="vab-data-empty"
            :src="require('@/assets/empty_images/data_empty.png')"
          />
        </template>
      </vxe-table>
    </div>
    <el-pagination
      background
      :current-page="form.pageNum"
      :layout="layout"
      :page-size="form.pageSize"
      :page-sizes="[10, 50, 100, 500, 1000]"
      :total="total"
      @current-change="handleCurrentChange"
      @size-change="handleSizeChange"
    />
  </div>
</template>
<script>
  import {
    lessThanTruckCostGetPageList,
    lessThanTruckCostGetExpire,
    lessThanTruckCostGetDelete,
    lessThanTruckCostGetSubmit,
    lessThanTruckCostGetCancelSubmit,
    lessThanTruckCostGetExport,
    lessThanTruckCostGetImportUrl,
    lessThanTruckCostGetImport,
    baseTemplateImportGetFileByCode,
  } from '@/api/productCenter'
  import { number } from 'echarts/lib/export'
  export default {
    props: {
      optionList: Object,
    },
    data() {
      return {
        anLoading: false,
        fold: true,
        listLoading: false,
        height: this.$baseTableHeight(2) - 50,
        list: [],
        form: {
          beginDate: '',
          endDate: '',
          supplierIds: [],
          code: '',
          // includingTaxes: '',
          pickName: '',
          dispatchName: '',
          status: '',
          productCommissionerId: '',
          pageNum: 1,
          pageSize: 100,
        },
        isQueryAll: false,
        total: 0,
        layout: 'total, sizes, prev, pager, next, jumper',
        optionListOne: [
          {
            id: 'inquiryNum',
            name: '询价量',
          },
          {
            id: 'dealNum',
            name: '已成交询价量',
          },
          {
            id: 'notDealNum',
            name: '未成交询价量',
          },
          {
            id: 'dealPercent',
            name: '询价成交率',
          },
          {
            id: 'quotationPriceSum',
            name: '报价总金额',
          },
          {
            id: 'dealPriceSum',
            name: '成交总金额',
          },
        ],
        optionListTwo: [
          {
            id: '0',
            name: '待提交',
          },
          {
            id: '1',
            name: '已提交',
          },
          {
            id: '2',
            name: '失效',
          },
        ],
        bulkOperations: {
          ids: [],
        },
        beginTime: [],
        beginTimeOne: [],
        pickerOptionsOne: {},
        pickerOptions: {},
        dialogVisible: false,
        file: '',
        haveFail: false,
        loading: false,
      }
    },
    mounted() {
      // this.queryList()
    },
    beforeMount() {
      window.addEventListener('resize', this.handleHeight)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.handleHeight)
    },
    methods: {
      handleFold() {
        this.fold = !this.fold
      },
      handleHeight() {
        this.height = this.$baseTableHeight(3) - 30
      },
      handleSizeChange(val) {
        this.form.pageNum = 1
        this.form.pageSize = val
        this.queryList()
      },
      handleCurrentChange(val) {
        this.form.pageNum = val
        this.queryList()
      },
      pickerClick() {
        this.form.beginDate = this.beginTime ? this.beginTime[0] : ''
        this.form.endDate = this.beginTime ? this.beginTime[1] : ''
        this.queryList()
      },
      pickerClickOne() {
        this.form.expiringBeginDate = this.beginTimeOne
          ? this.beginTimeOne[0]
          : ''
        this.form.expiringEndDate = this.beginTimeOne
          ? this.beginTimeOne[1]
          : ''
        this.queryList()
      },

      selectAllEvent({ records }) {
        this.bulkOperations.ids = records.map((item, index, array) => item.id)
      },
      // setSelectRows(val) {
      //   this.bulkOperations.ids = val.map((item, index, array) => item.id)
      // },
      rowStyle({ row }) {
        var arr = this.bulkOperations.ids
        for (let i = 0; i < arr.length; i++) {
          if (row.id === arr[i]) {
            return 'rowStyle'
          }
        }
      },
      handleRowClick(row, column) {
        if (column && column.label == '操作') {
          return
        }
        this.$refs.tableSort.toggleRowSelection(row)
      },
      handleBlur() {
        this.form.pageNum = 1
        this.queryList()
      },
      async queryList() {
        this.bulkOperations.ids = []
        // console.log(this.bulkOperations.ids)
        this.listLoading = true
        const res = await lessThanTruckCostGetPageList(this.form)
        this.total = res.data.totalSize
        this.list = res.data.pageList
        this.listLoading = false
      },
      //导入
      importExectUrl() {
        let val = lessThanTruckCostGetImportUrl()
        return val
      },
      async importExect(param) {
        var file = param.file
        //发送请求的参数格式为FormData
        const formData = new FormData()
        formData.append('file', file)
        lessThanTruckCostGetImport(formData)
        this.loading = true
        this.timer = setInterval(() => {
          this.loading = false
          clearInterval(this.timer)
          this.errorLink()
        }, 2000)
        // console.log(res)
        // if (res.code == 200) {
        //   this.$message({
        //     message: res.data.info,
        //     type: 'warning',
        //   })
        // this.haveFail = res.data.haveFail
        // this.queryList()
        // }
      },
      //提交
      async release() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认提交当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckCostGetSubmit(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定提交吗', '提示', async () => {
            const res = await lessThanTruckCostGetSubmit(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      //失效
      loseEfficacy() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认失效当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckCostGetExpire(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定失效吗', '提示', async () => {
            const res = await lessThanTruckCostGetExpire(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      //状态回退
      statusFallback() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认状态回退当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckCostGetCancelSubmit(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        } else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定状态回退吗', '提示', async () => {
            const res = await lessThanTruckCostGetCancelSubmit(
              this.bulkOperations
            )
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      // 模板下载
      async baseyCode() {
        const res = await baseTemplateImportGetFileByCode({
          code: 'LessThanTruckCostImpot',
        })
        window.open(res.data.url)
      },
      //导出
      async handExport() {
        this.anLoading = true
        this.$set(this.form, 'ids', this.bulkOperations.ids)
        const res = await lessThanTruckCostGetExport(this.form)
        const link = document.createElement('a')
        link.href = res.data.url
        link.click()
        this.anLoading = false
        this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
      },
      //删除
      handDelete() {
        if (this.isQueryAll) {
          const payload = {
            operateAll: true,
            ...this.bulkOperations,
            input: this.form,
          }
          this.$baseConfirm(
            '确认删除当前查询到的全部数据吗？',
            '提示',
            async () => {
              const res = await lessThanTruckCostGetDelete(payload)
              this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
              this.queryList()
            }
          )
        }else if (this.bulkOperations.ids.length != 0) {
          this.$baseConfirm('确定删除吗', '提示', async () => {
            const res = await lessThanTruckCostGetDelete(this.bulkOperations)
            this.$baseMessage(res.msg, 'success', 'vab-hey-message-success')
            this.queryList()
          })
        }
      },
      handleClose() {
        this.dialogVisible = false
      },
      errorLink() {
        this.dialogVisible = false
        // this.$router.push('/exportLog/exportLog')
        this.$router.push({
          name: 'ExportLog',
          params: {
            type: 'upload/LessThanTruckCost',
          },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .comprehensive-table-container {
    position: relative;
  }
  ::v-deep {
    .el-dialog__body {
      text-align: center;
    }
    .rowStyle {
      background-color: #e8f7fd !important;
      // color: #1890ff;
    }
  }
</style>
