<template>
  <div class="dashboard-container">
    <div>
      <el-tabs v-model="type"  @tab-click="handleClick">
        <el-tab-pane label="零担成本" name="1">
          <zero-load-cost :optionList="optionList" ref="tableOne"></zero-load-cost>
        </el-tab-pane>
        <el-tab-pane label="零担服务" name="2">
          <zero-load-serve :optionList="optionList" ref="tableTwo"></zero-load-serve>
        </el-tab-pane>
        <!-- <el-tab-pane label="拖车询价总览表" name="3">
          <trailer-report :optionList="optionList" ref="tab3"></trailer-report>
        </el-tab-pane> -->
      </el-tabs>
    </div>
    <!-- <div v-if="type == '1'">
      <inquiry-list :optionList="optionList"></inquiry-list>
    </div>
    <div v-if="type == '2'">
      <bulk-cargo-report :optionList="optionList"></bulk-cargo-report>
    </div>
    <div v-if="type == '3'">
        <trailer-report :optionList="optionList"></trailer-report>
    </div> -->
  </div>
</template>
<script>
import ZeroLoadCost from './components/ZeroLoadCost.vue'
import ZeroLoadServe from './components/ZeroLoadServe.vue'
import { productCommissionerGetValidList,baseSupplierGetValid } from '@/api/inquiryManagement'
export default {
  name:'TruckloadProducts',
  components: { ZeroLoadCost, ZeroLoadServe },
  data() {
    return {
      type: '1',
      optionList: {
        costomerGetValidList: [
          {
            id: '',
            name: '',
          },
        ],
        productCommissionerList: [], //产品专员
        baseSupplierGetValidList:[],//供应商
        taxList:[
          {
            id:1,
            name:'是'
          },
          {
            id:0,
            name:'否'
          }
        ]//是否含税
      },
    }
  },
  // mounted() {
  //   this.selectList()
  //   this.queryList()
  // },
  activated(){
    this.selectList()
    this.queryList()
  },
  methods: {
    queryList() {
      this.$nextTick(() => {
        if (this.type == '1') {
          this.$refs['tableOne'].queryList()
        } else {
          this.$refs['tableTwo'].queryList()
        }
      })
    },
    handleClick(){
      this.queryList()
    },
    async selectList() {
      Promise.all(
        [
          productCommissionerGetValidList({}),
          baseSupplierGetValid({}),
        ].map((v) => v.catch((e) => console.log(e)))
      ).then((res) => {
        this.optionList.productCommissionerList = res[0].data
        this.optionList.baseSupplierGetValidList = res[1].data
      })

      // const red = await productCommissionerGetValidList({})
      // this.optionList.productCommissionerList = red.data
      // const req = await baseSupplierGetValid({})
      // this.optionList.baseSupplierGetValidList = req.data
    },
  },
}
</script>
<style lang="scss">
  .el-tooltip__popper{
    max-width:20%
  }
</style>